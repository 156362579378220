export const CHANNEL_TYPES = {
  DRIBBLE: 'DRIBBLE',
  GITHUB: 'GITHUB',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN',
  TELEGRAM: 'TELEGRAM',
  TWITTER: 'TWITTER',
  WEBSITE: 'WEBSITE',
  YOUTUBE: 'YOUTUBE',
  AMAZON: 'AMAZON',
  OTHER: 'OTHER',
  MIT: 'MIT',
  HACHETTE: 'HACHETTE',
  BASIC: 'BASIC',
  PENGUIN: 'PENGUIN',
  AU1: 'AU1',
  US1: 'US1',
  GB1: 'GB1',
  DE1: 'DE1',
  CA1: 'CA1',
  BR1: 'BR1',
  FR1: 'FR1',
  IT1: 'IT1',
  MX1: 'MX1',
  NL1: 'NL1',
  SG1: 'SG1',
  AU2: 'AU2',
  GB2: 'GB2',
  DE2: 'DE2',
  CA2: 'CA2',
  BR2: 'BR2',
  FR2: 'FR2',
  IT2: 'IT2',
  MX2: 'MX2',
  NL2: 'NL2',
  IN1: 'IN1',
};


export const BLURBS = {
  HENRICH: 'HENRICH',
  ARMSTRONG: 'ARMSTRONG',
  SYED: 'SYED',
  MORRIS: 'MORRIS',
  GELFAND: 'GELFAND',
  FRANKOPAN: 'FRANKOPAN',
  RIDLEY: 'RIDLEY',
  MCAFEE: 'MCAFEE',
  KLITGAARD: 'KLITGAARD',
  HALL: 'HALL',
  YOELI: 'YOELI',
  HOFFMAN: 'HOFFMAN',
  HEYWOOD: 'HEYWOOD',
  BODANIS: 'BODANIS',
};

